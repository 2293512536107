import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineHome } from 'react-icons/ai';
import { FaUsers } from 'react-icons/fa';
import { RiNewspaperFill } from 'react-icons/ri';
import { TfiWrite } from 'react-icons/tfi';
import { NavLink,  useMatch } from 'react-router-dom';
import { useGetCurrentLoggedUserQuery } from '../../redux/features/userSlice';
import Logo from '../../assets/images/logo.png';

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
    const isMatch = useMatch('/layout');
    const{data:activeUser} =useGetCurrentLoggedUserQuery();
    const isAdmin = activeUser && activeUser.roles === 'admin';
    const trigger = useRef(null);
    const sidebar = useRef(null);
  
    const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
    const [sidebarExpanded,] = useState(
      storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
    );
  
    // close on click outside
    useEffect(() => {
      const clickHandler = ({ target }) => {
        if (!sidebar.current || !trigger.current) return;
        if (
          !sidebarOpen ||
          sidebar.current.contains(target) ||
          trigger.current.contains(target)
        )
          return;
        setSidebarOpen(false);
      };
      document.addEventListener('click', clickHandler);
      return () => document.removeEventListener('click', clickHandler);
    });
  
    // close if the esc key is pressed
    useEffect(() => {
      const keyHandler = ({ keyCode }) => {
        if (!sidebarOpen || keyCode !== 27) return;
        setSidebarOpen(false);
      };
      document.addEventListener('keydown', keyHandler);
      return () => document.removeEventListener('keydown', keyHandler);
    });

    useEffect(() => {
      localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());
      if (sidebarExpanded) {
        document.querySelector('body')?.classList.add('sidebar-expanded');
      } else {
        document.querySelector('body')?.classList.remove('sidebar-expanded');
      }
    }, [sidebarExpanded]);
  
    return (
      <main
        ref={sidebar}
        className={`absolute left-0 top-0 z-50 flex h-screen  flex-col overflow-y-hidden bg-black duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
       <section 
       ref={trigger}
       className='bg-[#021E34] h-screen '>
      <div className='text-white w-full  pt-4 pb-3 gap-2 flex pl-5
      border-b border-[#1A334A]
      '>
        <img src={Logo} className='w-[30px]' alt=''/>
        <h3 className='font-bold'>Co-Opts</h3>
      </div>
      <div className='flex mt-4 px-4 w-full'>
      <nav className='flex flex-col   w-full '>
        <NavLink  to=''>
        <span className={`text-white flex gap-2 items-center text-[14px] 
               p-2 mt-1 rounded-md ${isMatch ? 'bg-[#0B7DDD] ' : '' }`}>
              <AiOutlineHome size={22}/>
              <span className='pt-1'>Dashboard</span>
          </span>
        </NavLink>
        {isAdmin && 
          <NavLink  to='users'>
          {
           ({isActive}) =>(
             <span className={`text-white flex gap-2 items-center text-[14px] 
                p-2 mt-1 rounded-md ${isActive ? 'bg-[#0B7DDD] ' : '' }`}>
               <TfiWrite size={22}/>
               <span className='pt-1'>Publishers</span>
             </span>
           )
          }
         </NavLink>
        }
        
        <NavLink to='posts'>
         {
          ({isActive}) =>(
            <span className={`text-white flex gap-2 items-center text-[14px] 
               p-2 mt-1 rounded-md ${isActive ? 'bg-[#0B7DDD] ' : '' }`}>
              <RiNewspaperFill size={22}/>
              <span className='pt-1'>Posts</span>
            </span>
          )
         }
        </NavLink>
        <NavLink to='groups' >
         {
          ({isActive}) =>(
            <span className={`text-white flex gap-2 items-center text-[14px] 
               p-2 mt-1 rounded-md ${isActive ? 'bg-[#0B7DDD] ' : '' }`}>
              <FaUsers size={22}/>
              <span className='pt-1'>Groups</span>
            </span>
          )
         }
        </NavLink>  
      </nav>
      </div>
      
    </section>
      </main>
    );
  };


export default Sidebar;
