import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddBlog from './components/AddBog';
import BlogDetail from './components/BlogDetail';
import Blogs from './components/Blogs';
import DefaultLayout from './components/DashBoardLayout/Layout';
import UpdateBlog from './components/UpdateBlog';
import Users from './components/Users';
import AuthGuard from './utils/AuthGuard';
import LoginUser from "./components/LoginUser";
import GroupDetails from "./components/subscribers/GroupDetails";
import Dashboard from "./components/HomeOverView/Dashboard";
import PlayEditor from "./components/editor/Editor";



/** 
 * protect route from unauthorized or unauthenticated user
 * use token
*/

function App() {
  
  
  return (
    <BrowserRouter>
    <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"/>

    <Routes>
      <Route path='/' element={<LoginUser/>}/>
      <Route element={<AuthGuard/>}>
      <Route path='/layout'  element={<DefaultLayout/>} >
      <Route path='' element={<Dashboard/>}/>
      <Route path='posts' element={<Blogs/>} />
      <Route path='users' element={<Users/> }/>
      <Route path='newBlog'  element={<AddBlog/>} />
      <Route path='editBlog/:id' element={<UpdateBlog/>} />
      <Route path='blogdetails/:id' element={<BlogDetail/>} />
      <Route path='groups' element={<GroupDetails/>} />
      <Route path='play' element={<PlayEditor/>}/>
      </Route>
      </Route> 
    </Routes>
    </BrowserRouter>   
  );
}
export default App;
