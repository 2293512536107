import React from "react";
import ReactQuill from "react-quill";
import Toolbar, { formats,modules } from "./Toolbar";

const Editor = ({ editorState, handleChange }) => {
  return (
    <div className="text-editor  p-4  relative mt-1">
      <div className='absolute top-0 left-0 right-0' >
      <Toolbar />
      </div>
      <ReactQuill
        id = "editorcontainer"
        className='editor-container '
        theme="snow"
        value={editorState.value}
        onChange={handleChange}
        placeholder={"Write something here"}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default Editor;