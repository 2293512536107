import { baseApi } from "./baseApi";

const userApi = baseApi.injectEndpoints({
    tagTypes:['BloogersList'],
    endpoints: (build) =>({
        getBloggersList:build.query({
            query:() => `bloggersList/`,
            providesTags:['BloogersList']
        }),
        getCurrentLoggedUser:build.query({
            query:() => `currentUser`,
        }),
        addANewBlogger: build.mutation({
            query:(data) =>({
                url:'createUser',
                method:'POST',
                body:data
            }),
            invalidatesTags: ['BloogersList'],
        }),
        getBloggerDetails:build.query({
            query:(id) => `addBlogger/${id}`
           }),
           deleteBlogger: build.mutation({
            query:(id) => ({
                url:`deleteBlogger/${id}`,
                method:'DELETE'
            }),
            invalidatesTags: ['BloogersList'],
           }),
           addNewAdmin:build.mutation({
            query:(data) =>({
                url:'addSuperUser',
                method:'POST',
                body:data
            }),
            invalidatesTags: ['BloogersList'],
           })
       }),     
});

export const {useGetBloggersListQuery,
             useGetBloggerDetailsQuery,
             useAddANewBloggerMutation,
             useDeleteBloggerMutation,  
             useAddNewAdminMutation,
             useGetCurrentLoggedUserQuery
            } = userApi