import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  { month: "January", subscriptions: 120 },
  { month: "February", subscriptions: 180 },
  { month: "March", subscriptions: 220 },
  { month: "April", subscriptions: 150 },
  { month: "May", subscriptions: 200 },
  { month: "June", subscriptions: 280 },
  { month: "July", subscriptions: 300 },
  { month: "August", subscriptions: 240 },
  { month: "September", subscriptions: 190 },
  { month: "October", subscriptions: 230 },
  { month: "November", subscriptions: 270 },
  { month: "December", subscriptions: 210 },
];

const SubscriptionAnalyticsChart = () => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="subscriptions" fill="#34d399" />
      </BarChart>
    </ResponsiveContainer>
  );
};
export default SubscriptionAnalyticsChart;