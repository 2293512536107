import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { StyledButton } from '../../sharedComponents/StyledButton'

export default function MessageModal({messageModal,handleCloseModal, message}) {

  return (
    <>
     <Transition appear show={messageModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={handleCloseModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <div className='flex justify-between items-center border-b border-[#DDD] border-opacity-[0.4] pb-2'>   
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900  "
                  >
                    Message
                  </Dialog.Title>
                  <div className=""> 
                   
                    <span
                    onClick={handleCloseModal}
                    className="w-8 h-8 flex justify-center items-center shadow-close-shadow rounded-full cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                      >
                        <path
                          d="M12.002 4L4.00195 12"
                          stroke="#434343"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4.00195 4L12.002 12"
                          stroke="#434343"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                  </div>
                  

                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                     {message}
                    </p>
                  </div>
                  <div className='flex justify-center mt-2'>
                    <StyledButton 
                    btnText={'Close'} 
                    handleClick={handleCloseModal}
                    variant={'primary'}
                    classNames={"px-4 border-none outline-none"}/>
                  </div>
                 
                  
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

