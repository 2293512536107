import React from 'react'
import { Outlet,Navigate } from 'react-router'

/**
 * 
 * @returns 
 * guards routes from unauthorized users
 */

const AuthGuard = () => {
    const token = localStorage.getItem("token");
    return token ? <Outlet/> : <Navigate to='/' replace={true}/>
}

export default AuthGuard;