import { useEffect } from "react";
import { useState } from "react";
import { useGetBlogsListQuery } from "../../../redux/features/blogSlice";

function BlogsCard
() {
    const [lastWeekCount, setLastWeekCount] = useState(0);
    const [thisWeekCount, setThisWeekCount] = useState(0);
    const[differenceCount, setDifferenceCount] = useState(0);
    const [percentageChange, setPercentageChange] = useState(0);
    const {data:blogs} = useGetBlogsListQuery()
    
    useEffect(() => {
      async function fetchBlogData() {
        try {
          const currentDate = new Date();
    
          // Calculate the start of last week 
          const lastWeekStartDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate() - 7
          );
    
          // Calculate the start of this week
          const thisWeekStartDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate() - currentDate.getDay()
          );
    
          // Ensure that blogs is not undefined before filtering
          if (blogs) {
            // Filter blogs created last week
            let lastWeekBlogs = lastWeekCount
             lastWeekBlogs = blogs.filter(blog => {
              const createdAt = new Date(blog.createdAt);
              return createdAt >= lastWeekStartDate && createdAt < thisWeekStartDate;
            });
    
            // Filter blogs created this week
            let thisWeekBlogs = thisWeekCount
             thisWeekBlogs = blogs.filter(blog => {
              const createdAt = new Date(blog.createdAt);
              return createdAt >= thisWeekStartDate && createdAt < currentDate;
            });
    
            // Update the state with the counts
            setLastWeekCount(lastWeekBlogs.length);
            setThisWeekCount(thisWeekBlogs.length);
            setDifferenceCount(thisWeekBlogs.length - lastWeekBlogs.length);
    
            // Calculate percentage change
            if (lastWeekBlogs.length !== 0) {
              const percentage = ((thisWeekBlogs.length - lastWeekBlogs.length) / lastWeekBlogs.length) * 100;
              setPercentageChange(percentage);
            } else {
              // Handle division by zero when lastWeekCount is 0
              setPercentageChange(thisWeekBlogs.length === 0 ? 0 : 100);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    
      fetchBlogData();
    }, [blogs, lastWeekCount, thisWeekCount]);
    
  return (
    <div className="w-[230px] max-w-[278px] shadow-primaryShadow border bg-white border-[#F5F5FA] rounded-2xl px-5 py-3">
          <div className="flex items-center gap-2">
            <span className="w-8 h-8 flex items-center justify-center bg-[#FFF5EB] rounded">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="19"
                viewBox="0 0 20 19"
                fill="none"
              >
                <path
                  d="M6.23764 11.7701C7.96005 11.7701 9.35634 10.1749 9.35634 8.20711C9.35634 6.23933 7.96005 4.64413 6.23764 4.64413C4.51523 4.64413 3.11894 6.23933 3.11894 8.20711C3.11894 10.1749 4.51523 11.7701 6.23764 11.7701Z"
                  fill="#FB7E15"
                />
                <path
                  d="M12.475 17.0396C12.475 19.2042 9.29966 18.4648 6.23758 18.4648C3.17552 18.4648 0.000183105 19.2042 0.000183105 17.0396C0.000183105 14.875 3.17552 13.1203 6.23758 13.1203C9.29966 13.1203 12.475 14.875 12.475 17.0396Z"
                  fill="#FB7E15"
                />
                <path
                  d="M19.9652 4.62078C19.9418 4.58213 19.9079 4.54998 19.8669 4.52761C19.8259 4.50524 19.7793 4.49345 19.7319 4.49345H16.2182L16.8073 0.278955C16.8136 0.222551 16.7994 0.165796 16.7671 0.117828C16.7348 0.0698602 16.6862 0.0334712 16.6291 0.0145198C16.572 -0.0044316 16.5098 -0.00484259 16.4524 0.013353C16.395 0.0315486 16.3459 0.0672919 16.3129 0.114828L12.0398 7.12457C12.0148 7.16262 12.0011 7.20623 12.0001 7.25089C11.9992 7.29555 12.0109 7.33966 12.0342 7.37865C12.0575 7.41764 12.0914 7.45012 12.1326 7.47272C12.1737 7.49533 12.2206 7.50725 12.2683 7.50726H15.7295L15.2626 11.7281C15.258 11.7843 15.2737 11.8403 15.307 11.8873C15.3404 11.9342 15.3895 11.9694 15.4466 11.9871C15.5038 12.0049 15.5656 12.0043 15.6223 11.9853C15.679 11.9664 15.7273 11.9302 15.7596 11.8826L19.962 4.87363C19.9864 4.83552 19.9995 4.79203 20.0001 4.74761C20.0006 4.70319 19.9886 4.65943 19.9652 4.62078Z"
                  fill="#FB7E15"
                />
              </svg>
            </span>
            <p className="text-[#344054] text-sm font-semibold">Blogs</p>
          </div>
          
          <p className="text-[#344054] font-semibold text-xl mt-5 sm:mt-7">
          {Math.abs(differenceCount)}
          </p>
          {percentageChange === 0 ? 
           <div className="flex w-full  justify-end  gap-x-3 items-center">
           <svg
             xmlns="http://www.w3.org/2000/svg"
             width="10"
             height="10"
             viewBox="0 0 10 10"
             fill="none"
             className="rotate-90"
           >
             <path d="M5 0L9.33013 7.5H0.669873L5 0Z" fill="#e4d00a" />
           </svg>
           <span className="text-[#e4d00a] text-xs font-medium">
             {Math.abs(percentageChange)}%
           </span>
           <svg
             xmlns="http://www.w3.org/2000/svg"
             width="74"
             height="28"
             viewBox="0 0 74 28"
             fill="none"
           >
             <path
               d="M1 2V28H73V4L62 3L54.5 4L36 1.5L19.5 3.5L1 2Z"
               fill=""
               className="fill-[#EEDC82] opacity-40"
 
             />
             <path
               d="M1 1.75L19.5 3.25L36 1L54.5 4L62 2.5L73 4"
               stroke="#e4d00a"
             />
             <defs>
               <linearGradient
                 id="paint0_linear_16424_42803"
                 x1="37"
                 y1="2"
                 x2="37"
                 y2="28"
                 gradientUnits="userSpaceOnUse"
               >
                 <stop stopColor="#D946EF" />
                 <stop offset="1" stopColor="#D946EF" stopOpacity="0" />
               </linearGradient>
             </defs>
           </svg>
         </div>:
           <div className="w-full flex justify-end">
           {percentageChange > 0 ?
            <div className="flex items-center gap-3">
           <svg
             xmlns="http://www.w3.org/2000/svg"
             width="10"
             height="10"
             viewBox="0 0 10 10"
             fill="none"
           >
             <path d="M5 0L9.33013 7.5H0.669873L5 0Z" fill="#0DA06A" />
           </svg>
           <span className="text-[#0DA06A] text-xs font-medium">{Math.abs(percentageChange)}%</span>
           <svg
             xmlns="http://www.w3.org/2000/svg"
             width="75"
             height="30"
             viewBox="0 0 75 30"
             fill="none"
           >
             <path
               d="M22.3626 2.11204C16.033 0.917639 5.48352 10.0747 1 14.8025V30H73V2.11204C70.3626 4.8492 61.9231 13.3095 50.0549 10.3235C35.5095 6.66391 30.2747 3.60503 22.3626 2.11204Z"
               fill="url(#paint0_linear_16359_2484)"
             />
             <path
               d="M1.79102 14.0559C6.0108 9.57691 16.3493 0.91753 23.9449 2.11193C33.4394 3.60492 35.7739 8.35924 52.4284 10.3234C65.0877 11.8164 71.6811 4.10259 72.9998 2.11193"
               stroke="#0DA06A"
               strokeWidth="2.04"
               strokeLinecap="round"
             />
             <defs>
               <linearGradient
                 id="paint0_linear_16359_2484"
                 x1="37"
                 y1="2.00565"
                 x2="36.5"
                 y2="30"
                 gradientUnits="userSpaceOnUse"
               >
                 <stop stopColor="#8EF6D0" />
                 <stop offset="1" stopColor="#8EF6D0" stopOpacity="0" />
               </linearGradient>
             </defs>
           </svg>
         </div>: 
              <div className="flex items-center gap-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
              >
                <path d="M5 10L9.33013 2.5H0.669873L5 10Z" fill="#F34A7C" />
              </svg>
              <span className="text-[#F34A7C] text-xs font-medium">{Math.abs(percentageChange)}%</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="75"
                height="29"
                viewBox="0 0 75 29"
                fill="none"
              > 
                <path
                  d="M52 4C58.3297 2.8056 68.5165 9.07471 73 13.8025V29H1V1.11206C3.63736 3.84922 12.5549 6.17651 24 6.00002C38.997 5.76877 44.0879 5.493 52 4Z"
                  fill="url(#paint0_linear_16359_248)"
                />
                <path
                  d="M72.9998 13.0559C68.78 8.57693 57.5956 3.3056 50 4.5C40.5055 5.993 39.1545 3.53583 22.5 5.5C9.84066 6.993 3.10965 3.1026 1.79097 1.11194"
                  stroke="#F34A7C"
                  strokeWidth="2.04"
                  strokeLinecap="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_16359_248"
                    x1="37"
                    y1="1.00568"
                    x2="37.5"
                    y2="28.5"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FCD9E3" />
                    <stop offset="1" stopColor="#FCD9E3" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
           }
       </div>
          }
         
          <small className='text-[10px] inline-flex justify-end pt-3 ml-20 text-gray-400'>Compared to last week</small>

        </div>
  )
}

export default BlogsCard
