import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useGetSubscribersQuery } from "../../../redux/features/subscribersSlice";

function ClinitianCard() {
  const [lastWeekCount, setLastWeekCount] = useState(0);
  const [thisWeekCount, setThisWeekCount] = useState(0);
  const [differenceCount, setDifferenceCount] = useState(0);
  const [percentageChange, setPercentageChange] = useState(0);
  const { data: subscribers, isLoading } = useGetSubscribersQuery();

  useEffect(() => {
    async function fetchBlogData() {
      try {
        const currentDate = new Date();
  
        // Calculate the start of last week
        const lastWeekStartDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - 7
        );
  
        // Calculate the start of this week
        const thisWeekStartDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - currentDate.getDay()
        );
  
        if (!isLoading && subscribers) {
          const clinitian = subscribers.filter((sub) => {
            return sub.groupType === "clinitian-focus-group";
          });
          // Filter subscribers created last week
          let lastWeekBlogs = lastWeekCount
          lastWeekBlogs = clinitian.filter((focus) => {
            const createdAt = new Date(focus.createdAt);
            return (
              createdAt >= lastWeekStartDate && createdAt < thisWeekStartDate
            );
          });
  
          // Filter subscribers created this week
          let thisWeekBlogs = thisWeekCount
          thisWeekBlogs = clinitian.filter((focus) => {
            const createdAt = new Date(focus.createdAt);
            return createdAt >= thisWeekStartDate && createdAt < currentDate;
          });
  
          // Update the state with the counts
  
          setLastWeekCount(lastWeekBlogs.length);
          setThisWeekCount(thisWeekBlogs.length);
          setDifferenceCount(thisWeekBlogs.length - lastWeekBlogs.length);
  
          // Calculate percentage change
          if (lastWeekBlogs.length !== 0) {
            const percentage =
              ((thisWeekBlogs.length - lastWeekBlogs.length) /
                lastWeekBlogs.length) *
              100;
            setPercentageChange(percentage);
          } else {
            // Handle division by zero when lastWeekCount is 0
            setPercentageChange(thisWeekBlogs.length === 0 ? 0 : 100);
            setPercentageChange(lastWeekBlogs.length === 0 && thisWeekBlogs.length > 0 ? 100 : 0);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  
    fetchBlogData();
  }, [subscribers, isLoading, lastWeekCount, thisWeekCount]);
  
  return (
    <div className="w-[230px] max-w-[278px] shadow-primaryShadow border bg-white border-[#F5F5FA] rounded-2xl px-5 py-3">
      <div className="flex items-center gap-2">
        <span className="w-8 h-8 flex items-center justify-center bg-[#F9F5FF] rounded">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clipPath="url(#clip0_12729_87661)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5ZM13.939 7.655C13.586 6.772 12.321 6.822 12.037 7.729L10.285 13.335L9.897 12.558C9.8131 12.3903 9.6842 12.2494 9.52472 12.1508C9.36523 12.0523 9.18147 12 8.994 12H7C6.73478 12 6.48043 12.1054 6.29289 12.2929C6.10536 12.4804 6 12.7348 6 13C6 13.2652 6.10536 13.5196 6.29289 13.7071C6.48043 13.8946 6.73478 14 7 14H8.382L9.594 16.425C10.001 17.238 11.191 17.142 11.462 16.275L13.116 10.982L14.069 13.365C14.222 13.749 14.594 14 15.007 14H17C17.2652 14 17.5196 13.8946 17.7071 13.7071C17.8946 13.5196 18 13.2652 18 13C18 12.7348 17.8946 12.4804 17.7071 12.2929C17.5196 12.1054 17.2652 12 17 12H15.677L13.939 7.655Z"
                fill="#9E77ED"
              />
            </g>
            <defs>
              <clipPath id="clip0_12729_87661">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
        <p className="text-[#344054] text-sm font-semibold">Clinician Group</p>
      </div>

      <p className="text-[#344054] font-semibold text-xl mt-5 sm:mt-7">
        {Math.abs(differenceCount)}
      </p>
      {percentageChange === 0 ? (
        <div className="flex w-full  justify-end  gap-x-3 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            className="rotate-90"
          >
            <path d="M5 0L9.33013 7.5H0.669873L5 0Z" fill="#e4d00a" />
          </svg>
          <span className="text-[#e4d00a] text-xs font-medium">
            {Math.abs(percentageChange)}%
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="74"
            height="28"
            viewBox="0 0 74 28"
            fill="none"
          >
            <path
              d="M1 2V28H73V4L62 3L54.5 4L36 1.5L19.5 3.5L1 2Z"
              fill=""
              className="fill-[#EEDC82] opacity-40"

            />
            <path
              d="M1 1.75L19.5 3.25L36 1L54.5 4L62 2.5L73 4"
              stroke="#e4d00a"
            />
            <defs>
              <linearGradient
                id="paint0_linear_16424_42803"
                x1="37"
                y1="2"
                x2="37"
                y2="28"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#D946EF" />
                <stop offset="1" stopColor="#D946EF" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      ) : (
        <div className="w-full flex justify-end">
          {percentageChange > 0 ? (
            <div className="flex items-center gap-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
              >
                <path d="M5 0L9.33013 7.5H0.669873L5 0Z" fill="#0DA06A" />
              </svg>
              <span className="text-[#0DA06A] text-xs font-medium">
                {Math.abs(percentageChange)}%
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="75"
                height="30"
                viewBox="0 0 75 30"
                fill="none"
              >
                <path
                  d="M22.3626 2.11204C16.033 0.917639 5.48352 10.0747 1 14.8025V30H73V2.11204C70.3626 4.8492 61.9231 13.3095 50.0549 10.3235C35.5095 6.66391 30.2747 3.60503 22.3626 2.11204Z"
                  fill="url(#paint0_linear_16359_2484)"
                />
                <path
                  d="M1.79102 14.0559C6.0108 9.57691 16.3493 0.91753 23.9449 2.11193C33.4394 3.60492 35.7739 8.35924 52.4284 10.3234C65.0877 11.8164 71.6811 4.10259 72.9998 2.11193"
                  stroke="#0DA06A"
                  strokeWidth="2.04"
                  strokeLinecap="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_16359_2484"
                    x1="37"
                    y1="2.00565"
                    x2="36.5"
                    y2="30"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#8EF6D0" />
                    <stop offset="1" stopColor="#8EF6D0" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          ) : (
            <div className="flex items-center gap-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
              >
                <path d="M5 10L9.33013 2.5H0.669873L5 10Z" fill="#F34A7C" />
              </svg>
              <span className="text-[#F34A7C] text-xs font-medium">
                {Math.abs(percentageChange)}%
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="75"
                height="29"
                viewBox="0 0 75 29"
                fill="none"
              >
                <path
                  d="M52 4C58.3297 2.8056 68.5165 9.07471 73 13.8025V29H1V1.11206C3.63736 3.84922 12.5549 6.17651 24 6.00002C38.997 5.76877 44.0879 5.493 52 4Z"
                  fill="url(#paint0_linear_16359_248)"
                />
                <path
                  d="M72.9998 13.0559C68.78 8.57693 57.5956 3.3056 50 4.5C40.5055 5.993 39.1545 3.53583 22.5 5.5C9.84066 6.993 3.10965 3.1026 1.79097 1.11194"
                  stroke="#F34A7C"
                  strokeWidth="2.04"
                  strokeLinecap="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_16359_248"
                    x1="37"
                    y1="1.00568"
                    x2="37.5"
                    y2="28.5"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FCD9E3" />
                    <stop offset="1" stopColor="#FCD9E3" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          )}
        </div>
      )}

      <small className="text-[10px] inline-flex justify-end pt-3 ml-20 text-gray-400">
        Compared to last week
      </small>
    </div>
  );
}

export default ClinitianCard;
