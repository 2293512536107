import { baseApi } from "./baseApi";

const profileApi = baseApi.injectEndpoints({
        endpoints: (build) =>({
            getSubscribers: build.query({
                query:() => 'getSubscribers',
                providesTags:['Subscriber']
                 }),
            deleteSubscriber:build.mutation({
                query:(id) => ({
                        url:`deleteSubscriber/${id}`,
                        method:'DELETE'
                }),
                invalidatesTags:['Subscriber']
        }),
               
        })
           
});

export const  {useGetSubscribersQuery,useDeleteSubscriberMutation} = profileApi