import { useState } from 'react';
import {  useGetBloggersListQuery} from '../redux/features/userSlice';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { StyledButton } from './StyledButton';
import TableSkeletonLaoder from './loaders/TableSkeletonLoader';



const UserTable = () => {
  const [isOpen,setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const { data: users,isLoading } = useGetBloggersListQuery();



const handleOpenModal =() =>{
    setIsOpen(true);
  };

const handleCloseModal =() =>{
  setIsOpen(false);
}


  return (
    <div className="overflow-x-auto">
      {isLoading ? 
      <TableSkeletonLaoder/>
       : 
      <div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className='bg-[hsl(210,12%,93%)]'>
          <tr>
            <th className="px-6  w-1/4 text-left text-md leading-4 font-medium text-[#727482]  capitalize py-4">
              Name
            </th>
            <th className="px-6  w-1/4 text-left text-md leading-4 font-medium text-[#727482]  capitalize py-4">
              Email
            </th>
            <th className="px-6  w-1/4 text-left text-md leading-4 font-medium text-[#727482]  capitalize py-4">
              Role
            </th>
            <th className="px-6  w-1/4 text-left text-md leading-4 font-medium text-[#727482]  capitalize py-4">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {users?.map((user, index) => (
            <tr key={index} 
            className={`${
              index % 2 !== 0 && "bg-gray-100"}`}
             >
              <td className="px-6 py-2 whitespace-nowrap sm:table-cell">
                <div className="text-sm leading-5 text-gray-900 capitalize">
                  {`${user.firstName} ${user.lastName}`}
                </div>
              </td>
              <td className="px-6 py-2 whitespace-nowrap sm:table-cell">
                <div className="text-sm leading-5 text-gray-900">{user.email}</div>
              </td>
              <td className="px-6 py-2 whitespace-nowrap sm:table-cell">
                <div  className={`inline-block py-1 px-2 rounded-lg ${
                      user.roles === 'admin' ? 'bg-primary-blue text-white' : 'bg-gray-200'
                    } text-[14px]`}>{user.roles}</div>
              </td>
              {user.roles !== 'admin' &&
               <td className="px-6 py-2 flex ">
                <StyledButton variant={'primary'} BtnIcon={FaEdit} btnText={'Edit'}/>
                <StyledButton variant={'destructive'} BtnIcon={FaTrash} btnText={"Delete"}
                  handleClick={()=>{
                    handleOpenModal()
                    setSelectedUser(user._id);
                  }}
                />
              </td>}
            </tr>
          ))}
        </tbody>
      </table>
      <DeleteConfirmationModal 
      isOpen={isOpen} 
      handleOpenModal={handleOpenModal} 
      handleCloseModal={handleCloseModal}
      selectedUser={selectedUser}
      />
      </div>
    }
    </div>
  );
};

export default UserTable;
