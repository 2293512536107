import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import {
  useAddNewAdminMutation,
  useAddANewBloggerMutation,
} from '../redux/features/userSlice';
import { useEffect } from 'react';

const AddNewBlogger = ({ isOpen, openModal, isAdmin }) => {
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formErrors, setFormErrors] = useState('');
  const [isError, setIsError] = useState('');
  const [AddANewBloggerMutation, { isSuccess, error }] =
    useAddANewBloggerMutation();
  const [addNewAdminMutation, { isSuccess: success }] =
    useAddNewAdminMutation();
  const isFormValid = firstName && lastName && email && password;

  const formReset = () => {
    setfirstName('');
    setlastName('');
    setEmail('');
    setPassword('');
  };

  const handleRegistration = (e) => {
    e.preventDefault();
    if (!isFormValid) {
      setFormErrors('All fields are required');
      // setIsButtonDisabled(true);
      return;
    }

    try {
      if (isAdmin) {
        addNewAdminMutation({
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: password,
        });
        formReset();
      } else {
        AddANewBloggerMutation({
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: password,
        });
        formReset();
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (isSuccess || success) {
      openModal();
      setIsError('');
      toast.success('Blogger Added Successfully');
    } else {
      if (error) {
        setIsError(error?.data?.error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, success, error]);

  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={openModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[350px] transform z-50 overflow-hidden rounded-2xl bg-white p-6  text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between items-center border-b border-[#DDD] border-opacity-30 pb-4">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold text-secondaryblack"
                    >
                      Add User
                    </Dialog.Title>
                    <button
                      type="button"
                      onClick={openModal}
                      className="h-8 w-8 bg-white shadow-closeButton rounded-full inline-flex items-center justify-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M12 4L4 12"
                          stroke="#434343"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4 4L12 12"
                          stroke="#434343"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>

                  <div>
                    <div>
                      <p className="text-red-500 mb-2"> {isError}</p>
                      <form onSubmit={handleRegistration}>
                        <div className="flex flex-col gap-y-3">
                          <input
                            type="text"
                            id=""
                            name="firstName"
                            className={`py-2 px-6 border rounded-lg ${
                              formErrors && !firstName
                                ? 'border-red-500'
                                : 'border-gray-300'
                            } outline-none focus:outline-none placeholder:text-sm placeholder:text-gray-500`}
                            placeholder="First name"
                            value={firstName}
                            onChange={(e) => {
                              setfirstName(e.target.value);
                            }}
                          />
                          {formErrors && !firstName && (
                            <p className="text-red-500">{formErrors}</p>
                          )}
                          <input
                            type="text"
                            id=""
                            name="lastName"
                            className="py-2 px-6 border border-[#DDDDDD] rounded-lg outline-none focus:outline-none placeholder:text-sm placeholder:text-gray-500"
                            placeholder="Last name"
                            value={lastName}
                            onChange={(e) => {
                              setlastName(e.target.value);
                            }}
                          />
                          {formErrors && !lastName && <p>{formErrors}</p>}

                          <input
                            type="email"
                            id=""
                            name="email"
                            className="py-2 px-6 border border-[#DDDDDD] rounded-lg outline-none focus:outline-none placeholder:text-sm placeholder:text-gray-500"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                          {formErrors && <p>{formErrors}</p>}

                          <input
                            type="text"
                            id=""
                            name="password"
                            className="py-2 px-6 border border-[#DDDDDD] rounded-lg outline-none focus:outline-none placeholder:text-sm placeholder:text-gray-500"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                          />
                          <span className="text-xs px-3 ">
                            (Password: 6+ characters, at least one special
                            character, and one number.)
                          </span>
                          {formErrors && <p>{formErrors}</p>}

                          <div className="flex justify-center items-center mt-6 ">
                            {/* {!isAdmin ? */}
                            <button
                              className={`grow py-1 rounded-md sm:grow-0 sm:px-8 ${
                                !isFormValid
                                  ? 'bg-gray-200 cursor-not-allowed'
                                  : 'bg-[#6C63FF] hover:bg-[#5B53C1]'
                              }`}
                              type="submit"
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default AddNewBlogger;
