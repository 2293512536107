import React from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { useGetBlogDetailsQuery } from '../redux/features/blogSlice';
import Spinner from '../sharedComponents/Spinner';
import FormattedDate from '../utils/FormattedDate';

function BlogDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: blog, isLoading } = useGetBlogDetailsQuery(id);
  const sanitizedContent = blog ? DOMPurify.sanitize(blog.content) : '';

  const handleNavigate = (url) => {
    navigate(url);
  };

  return (
    <div>
      {isLoading && (
        <div className="flex justify-center items-center p-6 sm:p-10">
          <Spinner />
        </div>
      )}
      {blog && (
        <div className="container mx-auto py-8">
          <div className="max-w-2xl mx-auto">
            <h1 className="text-3xl font-bold mb-2 capitalize">{blog.title}</h1>
            {blog.publishDate ? (
              <span className="italic text-[14px] mb-1 ">
                {' '}
                Published: <FormattedDate releaseDate={blog.publishDate} />
              </span>
            ) : (
              <span className="italic text-[14px] mb-1 ">
                {' '}
                Published: <FormattedDate releaseDate={blog.createdAt} />
              </span>
            )}

            <p className="text-gray-500  italic text-[14px] mt-0 ">
              By ~ {`${blog.subTittle}`}
            </p>
            <div className="py-5">
              <img
                src={blog.coverImg}
                alt="Cover background representing the blog content"
                class=" px-auto  w-full lg:w-full  object-cover   h-[350px]  rounded-xl"
              />
            </div>
            <div className="ql-snow">
              <div
                className="ql-editor text-gray-800 leading-relaxed mb-6"
                dangerouslySetInnerHTML={{ __html: sanitizedContent }}
              ></div>
            </div>
            <div className="flex justify-end">
              <div
                onClick={() => handleNavigate('/layout/posts')}
                className="bg-primary-blue  cursor-pointer hover:bg-primary-hover text-white px-4 py-2 rounded-md"
              >
                Back to Dashboard
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BlogDetail;
