import SubscriberCard from './SubscriberCard';
import BlogsCard from './BlogsCard';
import FocusGroup from './FocusGroup';
import ClinitianCard from './ClinitianCard';

function CardsView() {
  return (
    <div className="mt-5 mx-auto flex flex-wrap gap-x-2 gap-y-5 justify-center md:justify-start">
        <BlogsCard/>
        <SubscriberCard/>
        <FocusGroup/>
        <ClinitianCard/>
      </div>
  )
}

export default CardsView