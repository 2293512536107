import { baseApi } from "./baseApi";

const authApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        login:build.mutation({
            query:(data) =>({
                url:'login',
                method:'POST',
                body:data
            }),
            
          })
      }),
      
});

export const {useSignUpMutation,useLoginMutation} = authApi;
