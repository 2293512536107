import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://server.co-opts.com/'
    : 'https://server.co-opts.com/';

export const baseApi = createApi({
  keepUnusedDataFor: 24 * 60 * 60 * 1000,
  reducerPath: 'baseApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    tagTypes: ['bloggerList'],
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
        ? JSON.parse(localStorage.getItem('token'))
        : '';

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});
