import React, { useState } from "react";
import { AiFillEye } from "react-icons/ai";
import{AiFillEyeInvisible} from "react-icons/ai";
import { useLoginMutation } from "../redux/features/authSlice";
import { useNavigate } from "react-router";
import Logo from "../assets/images/logo.png";
import Illastrator from "../assets/images/loginIllastrature.svg";
import Spinner from "../sharedComponents/Spinner";

const LoginUser = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const[showpassword, setShowPassword] = useState("password");
  const[formErrors, setFormError] = useState(null)
  const navigate = useNavigate();
  const [loginMutation, { isLoading, error: errors }] = useLoginMutation();
  const { data: formError } = errors || {};
  const { error: errorr } = formError || {};


  const showPassword = () =>{
    setShowPassword('text');
  }

  const hidePassword = () =>{
    setShowPassword('password');

  };


  const handleLogin = async (e) => {
    e.preventDefault();
    if(!password || !email) {
      setFormError('Feild is Required');
      return false;
    }
    const data = {
      email: email,
      password: password,
    };
      loginMutation(data)
      .then((result) => {
        if (result.data.token) {
          localStorage.setItem("token", JSON.stringify(result.data?.token));
          navigate("/layout", { replace: true });
        }
      })
      .catch((err) => {
        console.error(err);
      });
    
  };

  return (
    <div className="bg-gray-100 min-h-screen flex justify-center items-center">
      <div className="flex bg-gray-100 flex-col items-center justify-center w-full px-4">
        <div className="flex flex-col md:flex-row w-full max-w-screen-lg">
          <div className="w-full bg-gray-100 md:w-1/2 flex justify-center mb-8 mt-6 md:mb-0">
            <img
              className="hidden md:block max-w-full h-auto"
              src={Illastrator}
              alt=""
            />
          </div>
          <div className="w-full md:w-1/2 h-full flex items-center">
            <form
              action="javascript:void(0)"
              className="bg-white w-full mx-auto md:mx-4 px-4 mb-6 py-8 max-w-sm"
            >
              <div className="flex flex-col items-center mb-4">
                <img className="w-auto h-[23px]" src={Logo} alt="Logo" />
                <h3 className="font-bold text-xl mt-2">Welcome Back Mate!</h3>
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline
                  ${formErrors && !email ? 'border-red-500' : 'border-gray-300'}
                  `}
                  id="email"
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                {formErrors && !email && (
                  <p className="text-red-500 text-[14px] pt-1">{formErrors}</p>
                )}
              </div>
              <div className="mb-6 relative">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="password"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline
                     ${
                       formErrors && !password
                         ? 'border-red-500'
                         : 'border-gray-300'
                     }
                     `}
                    id="password"
                    type={showpassword}
                    name="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  {showpassword !== 'password' ? (
                    <AiFillEye
                      className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                      onClick={hidePassword}
                    />
                  ) : (
                    <AiFillEyeInvisible
                      className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                      onClick={showPassword}
                    />
                  )}
                </div>
                {formErrors && !password && (
                  <p className="text-red-500 text-[14px] pt-1">{formErrors}</p>
                )}
              </div>

              <div className="flex items-center justify-center">
                {isLoading ? (
                  <div className="flex justify-center items-center p-6 sm:p-10">
                    <Spinner />
                  </div>
                ) : (
                  <button
                    className="bg-primary-blue bg-primary-hover text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                    onClick={handleLogin}
                    disabled={isLoading}
                  >
                    Login
                  </button>
                )}
              </div>
              <p className="text-red-400">{errorr}</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginUser;
