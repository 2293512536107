import React from "react";
import BlogAnalyticsChart from "./Charts/BlogAnalyticsChart";
import SubscriptionAnalyticsChart from "./Charts/SubscriptionAnalyticsChart";
import { useGetUserProfileQuery } from "../../redux/features/profileSlice";
import CardsView from "./Cards/CardsView";
import SummaryOverview from "./SummaryOverview/SummaryOverview";
import Spinner from "../../sharedComponents/Spinner";

const Dashboard = () => {
  const { data: profileDetails, isLoading } = useGetUserProfileQuery();

  return (
   <>
      {isLoading ? <Spinner className='flex items-center' /> 
      :
      <div className="w-full p-8">
      <div className="flex flex-col gap-y-3 text-center sm:text-start">
        <h1 className="text-[#344054] text-xl font-semibold ">
          Dashboard Overview
        </h1>
        {!isLoading &&
          profileDetails?.map((profile) => (
            <div
              key={profile._id}
              className=" w-full flex items-center justify-center sm:justify-start  lg:gap-x-1 flex-nowrap"
            >
              <p className="text-[#344054] text-xl">
                Hello {profile.user.firstName},
                <span className="text-[#758494]">welcome back!</span>
              </p>
            </div>
          ))}
      </div>
      <CardsView />
      <section className="w-full mt-5 flex flex-col  gap-x-[14px] md:flex-row">
        <div className="w-full max-w-[862px] p-4 shadow-primaryShadow border bg-white border-[#F5F5FA] rounded-2xl">
          <div className="w-full">
            <h1 className="text-base text-[#344054] font-semibold">
              Blog Analytics
            </h1>
            <BlogAnalyticsChart />
          </div>
          <div className="w-full mt-4">
            <h1 className="text-base text-[#344054] font-semibold">
              Subscription Analytics
            </h1>
            <SubscriptionAnalyticsChart />
          </div>
        </div>
        <SummaryOverview />
      </section>
    </div>
    }
     </>
  );
};

export default Dashboard;
