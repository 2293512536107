import React, {  useState } from 'react'
import { StyledButton } from "../sharedComponents/StyledButton";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import FormattedDate from './FormattedDate'
import { DeleteConfirmationModal } from "../sharedComponents/DeleteConfirmationModal";


export const FilterData = ({data,navigateToCreateBlog}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
     <>
              {data?.map((blog, index) => (
                <tr
                  key={index}
                  className={`${index % 2 !== 0 && "bg-gray-100"} py-8`}
                >
                  <td className="px-6 py-2 whitespace-nowrap sm:table-cell">
                    <span className="text-sm leading-5 text-[#0A1327]  capitalize">
                      {blog.title.split(" ").slice(0, 5).join(" ")}
                      {blog.title.split(" ").length >= 5 && <span>....</span>}
                    </span>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap sm:table-cell">
                    <span className="text-sm leading-5 text-[#83868b]">{`${blog.subTittle}`}</span>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap sm:table-cell">
                    <span className="text-sm leading-5 text-gray-900 capitalize">
                      <span className=" font-medium px-2 text-[13px] py-1 rounded-lg
                      ">{blog.label}</span>
                    </span>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap sm:table-cell text-xs">
                    <span
                      className={`inline-block py-1 px-2 rounded-lg  text-[14px]`}
                    >
                      {blog.publishDate ?   <FormattedDate releaseDate ={blog.publishDate}/> :
                       <FormattedDate releaseDate ={blog.createdAt}/>
                      }
                     
                    </span>
                  </td>
                  <td className="px-6 py-2 flex ">
                    <span 
                    onClick={() => {navigateToCreateBlog(`/layout/editBlog/${blog._id}`)}}
                    className="max-w-min">
                      <StyledButton btnText={"Edit"} BtnIcon={FaEdit} variant={"primary"} />
                    </span>
                    <span 
                     onClick={() => {navigateToCreateBlog(`/layout/blogdetails/${blog._id}`)}}
                     className="max-w-min" >
                      <StyledButton btnText={"Preview"} BtnIcon={FaEye} variant={"outlined"} />
                    </span>

                    <StyledButton btnText={"Delete"} BtnIcon={FaTrash} variant={"destructive"} handleClick={() => {
                        handleOpenModal();
                        setSelectedId(blog._id);
                      }}/>
                  </td>
                </tr>
              ))}
            <DeleteConfirmationModal
            isOpen={isOpen}
            handleOpenModal={handleOpenModal}
            handleCloseModal={handleCloseModal}
            selectedId={selectedId}
          />
  </>
  )
}
