import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  { date: "2023-01-01", posts: 10 },
  { date: "2023-02-01", posts: 15 },
  { date: "2023-03-01", posts: 25 },
  { date: "2023-04-01", posts: 18 },
  { date: "2023-05-01", posts: 22 },
  { date: "2023-06-01", posts: 30 },
  { date: "2023-07-01", posts: 28 },
  { date: "2023-08-01", posts: 35 },
];

const BlogAnalyticsChart = () => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="posts" stroke="#8884d8" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default BlogAnalyticsChart;