import React from "react";
import Spinner from "../sharedComponents/Spinner";
import { FilterData } from "../utils/FilterData";

const BlogsTable = ({
  blogs,
  filterdData,
  isLoading,
  buttonArray,
  pageToggle,
  current,
  handlePreviousPage,
  handleNextPage,
  totalPages,
  navigateToCreateBlog
}) => {
 
  return (
    <div className="overflow-x-auto">
      {isLoading ? (
        <div className="flex justify-center items-center p-6 sm:p-10">
          <Spinner />
          <p>spinner</p>
        </div>
      ) : (
        <div className="overflow-x-scroll">
          <table className="min-w-full divide-y divide-gray-200 ">
            <thead className="bg-[hsl(210,12%,93%)] ">
              <tr className="px-4 font-semibold ">
                <th className="px-6  w-1/4 text-left text-md leading-4  text-[#727482]  capitalize py-4">
                  Title
                </th>
                <th className="px-6 py-3 w-1/4 text-left text-md leading-4  text-[#727482]  capitalize">
                  Author
                </th>
                <th className="px-6 py-3 w-1/4 text-left text-md leading-4  text-[#727482]  capitalize">
                  Category
                </th>
                <th className="px-6 py-3 w-1/4 text-left text-md leading-4  text-[#727482]  capitalize">
                  Published date
                </th>
                <th className="px-6 py-3 w-1/4 text-left text-md leading-4  text-[#727482]  capitalize">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
            <FilterData data={filterdData} navigateToCreateBlog={navigateToCreateBlog}/>
            </tbody>     
          </table>
          <div className="flex flex-row justify-between items-center mt-2 px-4">
            <h1>
              <span className="text-secondaryblack text-sm font-normal">
                1 of 20
              </span>
            </h1>
            <div className="flex items-center gap-2">
              <button
                className={`${current === 1 && "hidden"}`}
                onClick={handlePreviousPage}
                type="button"
              >
                <svg
                  className=""
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 12.5L6 8.5L10 4.5"
                    stroke="#344054"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              {buttonArray?.map((pages, index) => (
                <button
                  onClick={() => pageToggle(pages)}
                  type="button"
                  className={`h-[27px] w-[26px] rounded-full 
                    font-medium text-sm transition-all ease-in-out duration-200 ${
                      current === pages
                        ? " text-white bg-primary-blue"
                        : "bg-gray-200 text-gray-700"
                    }`}
                  key={index}
                >
                  {pages}
                </button>
              ))}
              <button
                className={`${current === totalPages && "hidden"}`}
                onClick={handleNextPage}
                type="button"
              >
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 12.5L10 8.5L6 4.5"
                    stroke="#344054"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          
        </div>
      )}
    </div>
  );
};

export default BlogsTable;
