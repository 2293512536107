import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";
import TrashImage from "../assets/images/trash.svg";
import { useDeleteBloggerMutation } from "../redux/features/userSlice";
import { useDeleteBlogMutation } from "../redux/features/blogSlice";
import { toast } from "react-toastify";
import { StyledButton } from "./StyledButton";
import { useDeleteSubscriberMutation } from "../redux/features/subscribersSlice";

export const DeleteConfirmationModal = ({
  isOpen,
  handleCloseModal,
  selectedUser,
  selectedId,
  selecectedSubScriberId
}) => {
  const [deleteBloggerMutation, { isSuccess: successDelteUser }] = useDeleteBloggerMutation();
  const [deleteBlog, { isSuccess: successDeleteBlog }] = useDeleteBlogMutation();
  const [deleteSubscriber,{ isSuccess: successSubscriber }] = useDeleteSubscriberMutation()

  const handleDeletBlogger = () => {
    deleteBloggerMutation(selectedUser);
    handleCloseModal();
  };
  const handleDeleteBlog = () => { 
    selectedId ? deleteBlog(selectedId) : deleteSubscriber(selecectedSubScriberId)
    handleCloseModal();
  };

  useEffect(() => {
    if (successDelteUser || successDeleteBlog ||successSubscriber) {
      toast.success("successfully deleted");
    }
  }, [successDelteUser, successDeleteBlog,successSubscriber]);

  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={handleCloseModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[400px] transform z-50 overflow-hidden bg-white p-6  text-left align-middle shadow-xl transition-all rounded-md">
                  <div className="flex justify-between items-center border-b border-[#DDD] border-opacity-30 pb-4"></div>
                  <div className="flex flex-col ">
                    <p className="text-center mb-2">
                      Are you sure you want to Delete?
                    </p>
                    <img src={TrashImage} className="h-[150px]" alt="delete" />
                    <div className="flex  mt-3 justify-around items-center ">
                      <StyledButton
                        classNames={"px-8"}
                        variant={"primary"}
                        btnText={"Cancel"}
                        handleClick={handleCloseModal}
                      />
                      <StyledButton
                        classNames={"px-8"}
                        variant={"destructive"}
                        btnText={"Delete"}
                        handleClick={
                          selectedUser ? handleDeletBlogger : handleDeleteBlog
                        }
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
