import React, { useEffect, useState } from 'react';
import { useGetBlogsQuery } from '../redux/features/blogSlice';
import BlogsTable from './BlogsTable';
import CategoryFilter from './formComponents/CategoryFilter';
import { useNavigate } from 'react-router-dom';
import TableSkeletonLaoder from '../sharedComponents/loaders/TableSkeletonLoader';

function Blogs() {
  const [currentPage, setCurrentPage] = useState(1);
  const [title, setTitle] = useState();
  const [selectedCategory,] = useState(null);
  const [selectedAuthor, setSelectedAuthor] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const navigate = useNavigate();
  const { data, isLoading } = useGetBlogsQuery(currentPage);
  const { allBlogs: blogs, totalPages } = !isLoading && data;
  const [filterdData, setFilterdData] = useState([]);
  const [authorList, setAuthorList] = useState([]);

  useEffect(() => {
    if (blogs) {
      setFilterdData(blogs);
      const authorDropdownList = blogs.map((blog) => ({
        id: blog._id,
        name: blog.subTittle,
      }));
      setAuthorList(authorDropdownList);
    }
  }, [blogs]);

  const navigateToCreateBlog = (url) => {
    navigate(url);
  };

  const buttonArray = Array.from(Array(totalPages), (_, index) => index + 1);

  const pageToggle = (page) => {
    setCurrentPage(page);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  //handle filter by title
  const handleFilterByTitle = () => {
    const filterByTitle = blogs.filter((blog) => {
      return blog.title.toLowerCase().includes(title.toLowerCase());
    });
    setFilterdData(filterByTitle);
  };

    const handleAuthorChange = (author) => {
    setSelectedAuthor(author);
    handleFilterCategory();
  };

  const handleFilterCategory = () => {
    if (selectedCategory && selectedCategory.name) {
      const filterByCategory = blogs?.filter((blog) => {
        return blog.label.toLowerCase() === selectedCategory.name.toLowerCase();
      });
      setFilterdData(filterByCategory);
    } else if (selectedAuthor && selectedAuthor.name) {
      const filterByAuthor = blogs?.filter((blog) => {
        const authorName = `${blog.subTittle}`;
        const formattedAuthorName = authorName.toLowerCase().trim();
        const formattedSelectedAuthorName = selectedAuthor.name
          .toLowerCase()
          .trim();
        return formattedAuthorName === formattedSelectedAuthorName;
      });
      setFilterdData(filterByAuthor);
    } else {
      setFilterdData(blogs);
    }
  };

  useEffect(() => {
    setSelectedDate(new Date().toISOString().substr(0, 10));
  }, []);

  useEffect(() => {
    handleFilterCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, selectedAuthor, selectedDate]);

  return (
    <div className=" overflow-x-auto mt-4 mx-4 border border-gray-300  shadow-secondaryShadow  bg-white rounded-xl">
      <div className="lg:mx-auto border-b ">
        <div className=" flex justify-between items-center p-4">
          <div onClick={() => navigateToCreateBlog('/layout/newBlog')}>
            <h6 className=" cursor-pointer text-[24px] inline-flex justify-center items-center gap-2  font-[500]">
              Add Posts
              <span
                className=" cursor-pointer inline-flex justify-center items-center h-7 w-7 border rounded-full bg-[#0B7DDD]
             text-white text-[15px] mt-1 "
              >
                +
              </span>
            </h6>
          </div>
          <div className="flex items-center gap-x-2  text-sm text-[#9398A2] font-semibold">
            <p>All</p>
            <p>Drafts</p>
          </div>
        </div>
      </div>
      <div className="container mx-auto pb-4   ">
        <small className="px-4 pt-1 font-medium">Filter By:</small>
        <div className="border-b px-4 grid grid-cols-1 lg:grid-cols-3 gap-x-4 pt-1 pb-3 ">
          <input
            type="text"
            className="mt-1 px-3 py-2 bg-white border shadow-sm border-[#DCDDE4]placeholder-slate-400 focus:outline-none  block  rounded-md sm:text-sm "
            placeholder="Filter by Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onKeyUp={handleFilterByTitle}
          />
          <div>
            {authorList && (
              <CategoryFilter
                data={authorList}
                inputText={'Filter by author'}
                onSelectedValue={handleAuthorChange}
              />
            )}
          </div>
        </div>
      </div>

      <div className="container mx-auto pb-8 border-b">
        {isLoading ? (
          <TableSkeletonLaoder />
        ) : (
          <BlogsTable
            blogs={blogs}
            filterdData={filterdData}
            buttonArray={buttonArray}
            pageToggle={pageToggle}
            current={currentPage}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            totalPages={totalPages}
            navigateToCreateBlog={navigateToCreateBlog}
          />
        )}
      </div>
    </div>
  );
}

export default Blogs;
