import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import{AiOutlineDash} from 'react-icons/ai';
import { useGetSubscribersQuery } from "../../redux/features/subscribersSlice";
import { DeleteConfirmationModal } from "../../sharedComponents/DeleteConfirmationModal";
import { StyledButton } from "../../sharedComponents/StyledButton";
import TableSkeletonLaoder from "../../sharedComponents/loaders/TableSkeletonLoader";
import MessageModal from "./MessageModal";

export default function GroupDetails() {
  const [isOpen, setIsOpen] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [message, setMessage] = useState(null);
  const [selecectedSubScriberId, setSelectedSubscriberId] = useState(null);
  const { data: subscribers, isLoading } = useGetSubscribersQuery();
  
  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setMessageModal(false);
  };

  const openMessageModal = (message) => {
    setMessageModal(true);
    setMessage(message);
  };

  return (
    <div className=" mt-4 mx-4 border border-gray-300  shadow-secondaryShadow  bg-white rounded-xl">
      {/* <div className="flex flex-col md:flex-row md:justify-between items-center mb-4">
          <h1 className="font-semibold text-lg mr-4 text-gray-600">Users</h1>
          <div>
          <button
            className="px-3 py-1  bg-[#6C63FF] text-white md:mr-4 mt-4 md:mt-0 rounded"
            onClick={()=>{
              setIsAdmin(false);
              openModal()
            }} >       
            Add Blogger
          </button>
          <button
            className="px-3 py-1  bg-gray-400 text-white md:mr-0 mt-4 md:mt-0 rounded"
            onClick={()=>{
              setIsAdmin(true);
              openModal()
            }}
          >
            Add Admin
          </button>
          </div>
        </div> */}
      <div className=" border-b ">
        <div className=" p-4">
          <h6 className="text-[24px] inline-flex justify-center items-center gap-2  font-[500]">
            Subscribers
          </h6>
        </div>
      </div>
      <div className="container mx-auto pb-8 border-b">
        <div className="overflow-x-auto">
          {isLoading ? (
            <TableSkeletonLaoder />
          ) : (
            <div>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-[hsl(210,12%,93%)]">
                  <tr>
                    <th className="px-6  w-1/4 text-left text-md leading-4 font-medium text-[#727482]  capitalize py-4">
                      Name
                    </th>
                    <th className="px-6  w-1/4 text-left text-md leading-4 font-medium text-[#727482]  capitalize py-4">
                      Email
                    </th>
                    <th className="px-6  w-1/4 text-left text-md leading-4 font-medium text-[#727482]  capitalize py-4">
                      GroupType
                    </th>
                    <th className="px-6  w-1/4 text-left text-md leading-4 font-medium text-[#727482]  capitalize py-4">
                      NewsUpdates
                    </th>
                    <th className="px-6  w-1/4  text-md leading-4 font-medium text-[#727482]  capitalize py-4 text-center">
                      Message
                    </th>
                    <th className="px-6  w-1/4 text-left text-md leading-4 font-medium text-[#727482]  capitalize py-4">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {subscribers?.map((sub, index) => (
                    <tr
                      key={index}
                      className={`${index % 2 !== 0 && "bg-gray-100"}`}
                    >
                      <td className="px-6 py-2 whitespace-nowrap sm:table-cell">
                        <div className="text-sm leading-5 text-gray-900 capitalize">
                          {`${sub.subscriberName} `}
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap sm:table-cell">
                        <div className="text-sm leading-5 text-gray-900">
                          {sub.email}
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap sm:table-cell">

                        <div
                          className={`inline-block py-1 px-2 rounded-lg text-[14px]`}
                        >
                          {sub.groupType ? <span>{sub.groupType}</span>:<AiOutlineDash size={20} className=" text-lg"/>}
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap sm:table-cell text-left">
                        <div
                          className={`inline-block py-1 px-2 rounded-lg text-[14px] capitalize `}
                        >
                             <button
                             type="button"
                             className={`${sub.newsUpdates === true ? 'text-[#12B76A] bg-[#F6FEF9] ':'text-red-400 bg-red-50'} inline-flex items-center gap-2 rounded-lg px-4 py-1 shadow-success  font-normal`}
                           >
                            <span className="relative flex h-2 w-2">
                            <span className={`${sub.newsUpdates === true ? 'animate-ping':''}  absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75`}></span>
                          <span className={`${sub.newsUpdates === true ? 'bg-[#34C759]':'bg-red-400'} relative inline-flex rounded-full h-2 w-2 bg-[#34C759]`}></span>
                           
                            </span>
                            
                             <span className="capitalize "> {sub.newsUpdates.toString()}</span>
                           </button>

                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap sm:table-cell">
                        <div
                          className={`inline-block py-1 px-2 rounded-lg text-[14px]`}
                        >
                          <div
                            className={`inline-block py-1 px-2 rounded-lg text-[14px]`}
                          >
                            {!sub.message ?<AiOutlineDash size={20} className=" text-lg"/>:
                            <span>
                            {sub.message && sub.message.length > 6 ? (
                              <p className="">
                                {sub.message.substring(0, 20) + "..."}
                                <span
                                  className=" border border-primary-blue rounded-lg px-[5px] py-[3px] text-primary-blue ml-1 text-xs cursor-pointer bg-white "
                                  onClick={() => {
                                    openMessageModal(sub.message);
                                  }}
                                >
                                  View
                                </span>
                              </p>
                            ) : (
                              sub.message
                            )}
                            </span>
                             } 
                            
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-2 flex ">
                        <StyledButton
                          variant={"destructive"}
                          BtnIcon={FaTrash}
                          btnText={"Delete"}
                          handleClick={() => {
                            handleOpenModal();
                            setSelectedSubscriberId(sub._id);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <DeleteConfirmationModal
                isOpen={isOpen}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
                selecectedSubScriberId={selecectedSubScriberId}
              />
              <MessageModal
                messageModal={messageModal}
                message={message}
                handleCloseModal={handleCloseModal}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
