import React from 'react'
import { useGetBlogsListQuery } from '../../../redux/features/blogSlice'

export default function RecentsBlogs() {
  const {data:blogs} = useGetBlogsListQuery();
  let recentBlogs = blogs?.slice(-3)

  return (
    <div className="w-full shadow-primaryShadow border bg-white border-[#F5F5FA] rounded-2xl p-5 mt-4">
    <h1 className="text-base text-[#344054] font-semibold">
      Recent activities
    </h1>
    {recentBlogs?.map((blog)=>(
       <div key={blog._id} className="w-full divide-y mt-4 divide-[rgb(235,238,243)] flex flex-col gap-y-4">
      <div className="flex gap-x-4  py-3">
        <div className="w-[34.286px] h-[34.286px] relative bg-white">
          
          <div className="h-3 w-3 bg-[#FFFFFF] shadow-closeButton absolute top-0 right-0 rounded-full flex justify-center items-center">
            <div className="bg-[#FCE1D4] h-2.5 w-2.5 rounded-full flex items-center justify-center">
              <p className="bg-[#FC2401] h-1 w-1 rounded-full"></p>
            </div>
          </div>
        </div>
        <div className="">
          <div className="flex justify-between">
            <h1 className="max-w-[158px] text-[#344054] text-sm font-medium">
              {blog.title}
            </h1>
            <div className="flex items-center gap-1">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="5"
                  height="6"
                  viewBox="0 0 5 6"
                  fill="none"
                >
                  <circle
                    cx="2.5"
                    cy="3.00049"
                    r="1.5"
                    fill="#C0C0C0"
                  />
                </svg>
              </span>
              <p className="text-[#C0C0C0] text-[10px] font-medium">
                1h ago
              </p>
            </div>
          </div>
          <p className="text-xs text-[#758494] font-light max-w-[241px]">
            {blog.title}
            <button className="text-primary mx-1"> Read more</button>
          </p>
        </div>
      </div>
    </div>
    ))}
   
  </div>
  )
}
