import React, { useState } from 'react';
import Table from '../sharedComponents/UserTable';
import AddNewBlogger from './AddNewBlogger';
import { Link } from 'react-router-dom';

function Users() {
  const [isOpen, setIsOpen] = useState(false);
  const[isAdmin ,setIsAdmin] = useState(true);

 
const openModal = () =>{
  setIsOpen(!isOpen);
}

  return (
    <>
      <div className=" mt-4 mx-4 border border-gray-300  shadow-secondaryShadow  bg-white rounded-xl">
        {/* <div className="flex flex-col md:flex-row md:justify-between items-center mb-4">
          <h1 className="font-semibold text-lg mr-4 text-gray-600">Users</h1>
          <div>
          <button
            className="px-3 py-1  bg-[#6C63FF] text-white md:mr-4 mt-4 md:mt-0 rounded"
            onClick={()=>{
              setIsAdmin(false);
              openModal()
            }} >       
            Add Blogger
          </button>
          <button
            className="px-3 py-1  bg-gray-400 text-white md:mr-0 mt-4 md:mt-0 rounded"
            onClick={()=>{
              setIsAdmin(true);
              openModal()
            }}
          >
            Add Admin
          </button>
          </div>
        </div> */}
         <div className=" border-b ">
        <div className="flex justify-between items-center p-4" >
          <Link
            onClick={() =>{
              setIsAdmin(false)
              openModal()
            }}
          >
          <h6 className="text-[24px] inline-flex justify-center items-center gap-2  font-[500]">
            Publishers
            <span
              className="inline-flex justify-center items-center h-7 w-7 border rounded-full bg-[#0B7DDD]
             text-white text-[15px] mt-1 "
            >+
            </span>
          </h6>
          </Link>
          <div className="flex items-center gap-x-2  text-sm text-[#9398A2] font-semibold">
          <p>All</p>
          <p>Admins</p>
          <p>Publishers</p>
          </div>   
        </div>
      </div>
        <div className='container mx-auto pb-8 border-b'>
          <Table />
        </div>
      </div>
      <AddNewBlogger isOpen={isOpen} openModal={openModal}  isAdmin={isAdmin}/>
    </>
  );
}

export default Users;
