import { BiMenuAltLeft, BiSearch } from "react-icons/bi";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoMdArrowDropdown } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import { useGetUserProfileQuery } from "../../redux/features/profileSlice";
import { baseApi } from "../../redux/features/baseApi";
import { useDispatch } from "react-redux";

const Header = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const { data: profileDetails, isLoading } = useGetUserProfileQuery();

  if (!isLoading) {
    profileDetails?.map((d) => {
      return d;
    });
    
  }
  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch(baseApi.util.resetApiState());
    navigate("/");
  };

  const toggleLogoutDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  return (
    <header className="sticky top-0 z-999 flex w-full ">
      <div className="flex flex-grow  items-center justify-between gap-x-3  px-4 shadow-2 md:px-6 2xl:px-11">
        <div className="flex   gap-2  lg:hidden">
          {/* <!-- Hamburger Toggle BTN --> */}
          {!props.sidebarOpen ? (
            <button
              aria-controls="sidebar"
              onClick={(e) => {
                e.stopPropagation();
                props.setSidebarOpen(true);
              }}
              className="z-99999 block rounded-sm border border-stroke bg-white p-1.5 shadow-sm dark:border-strokedark dark:bg-boxdark lg:hidden"
            >
              <BiMenuAltLeft />
            </button>
          ) : (
            <button
              aria-controls="sidebar"
              onClick={(e) => {
                e.stopPropagation();
                props.setSidebarOpen(false);
              }}
              className="z-99999 block rounded-sm border border-stroke bg-white p-1.5 shadow-sm dark:border-strokedark dark:bg-boxdark lg:hidden"
            >
              <AiOutlineClose />
            </button>
          )}
        </div>

        <div className=" lg:w-full sm:flex  gap-x-3 justify-between">
          <div className="w-full max-w-[350px] hidden sm:hidden  relative md:flex md:flex-col  md:justify-center ">
            <BiSearch className="absolute  md:inline  sm:hidden text-[#A2A4AE] translate-x-4 translate-y-0.5 pointer-events-none" />
            <input
              type="text"
              placeholder="Search.."
              className="border  hidden md:inline border-gray-300 w-full rounded-lg outline-none sm:hidden placeholder-[#A2A4AE] px-8 py-2.5 "
            />
          </div>
          <div className="">
            <div
              className="flex justify-center items-center gap-x-3 lg:gap-x-1 border-l border-gray-300 p-2 cursor-pointer"
              onClick={toggleLogoutDropdown}
            >
              {!isLoading &&
                profileDetails?.map((profile) => (
                  <div
                    key={profile._id}
                    className=" w-full flex items-center justify-center  lg:gap-x-1 flex-nowrap"
                  >
                    <img
                      src={profile.profileImage}
                      className="w-6 h-6 rounded-full"
                      alt='profile'
                    />
                    <p className="hidden md:block lg:block text-[#121D37]">
                      {profile.user.email}
                    </p>
                    <IoMdArrowDropdown className="mt-1 " />
                  </div>
                ))}
            </div>
            {showDropdown && (
              <div className="absolute right-0 mt-0 mb-5 w-28 border  bg-white rounded shadow flex flex-col justify-center items-center">
                <Link className="block w-full  px-4 py-1 hover:bg-primary-hover rounded-md border-b mb-1 text-white border-white bg-primary-blue  text-center">
                  Profile
                </Link>
                <button
                  className="block w-full  bg-primary-blue  px-4 py-1 text-white hover:bg-primary-hover rounded-md text-center"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
