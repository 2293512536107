import React from "react";

export const StyledButton = ({variant, BtnIcon, btnText, handleClick,classNames}) => {

    const variants = {
        primary: "bg-primary-blue hover:bg-primary-hover text-white",
        outlined: "border border-1 border-gray-200 text-black bg-white hover:bg-neutral-100",
        destructive: "text-red-500 hover:text-red-600 ml-2 border border-red-500 hover:bg-red-100"
    }
  return (
    <div>
      <button className={`${variants[variant]} ${classNames} text-xs ml-2 transition-colors first-letter px-2 py-2 rounded-md inline-flex justify-center items-center gap-x-1`}  onClick={handleClick}>
        {BtnIcon && <BtnIcon />}
        {btnText}
      </button>
    </div>
  );
};
