import { baseApi } from "./baseApi";

const blogApi = baseApi.injectEndpoints({
    tagTypes:['Blogs'],
    endpoints:(build) =>({
        getBlogs: build.query({
            query: (pageNumber) => `blogs?page=${pageNumber}`,
            providesTags:['Blogs']
        }),
        getBlogsList: build.query({
            query: () => `allBlogs`,
            providesTags:['Blogs']
        }),
        addNewBlog:build.mutation({
            query:(data) =>({
                url:'addBlog',
                method:'POST',
                body:data
            }),
            invalidatesTags:['Blogs']
        }),
        getBlogDetails:build.query({
            query:(id) =>({
                url:`blog/${id}`,
                method:'GET'
            }),
            providesTags:['BlogsDetails']

        }),
        updateBlog: build.mutation({
            query:({id,blogData}) =>({
                url:`blog/${id}`,
                method:'PATCH',
                body:blogData
            }),
            invalidatesTags:['Blogs','BlogsDetails']
        }),
        deleteBlog:build.mutation({
            query:(id) =>({
                url:`blog/${id}`,
                method:'DELETE'
            }),
            invalidatesTags:['Blogs']
        })

    })

})
 export const { useGetBlogsQuery,
                useAddNewBlogMutation,
                useDeleteBlogMutation,
                useGetBlogDetailsQuery,
                useUpdateBlogMutation,
                useGetBlogsListQuery,
              } = blogApi;
