function FormattedDate({ releaseDate }) {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = getDayWithOrdinalSuffix(date.getDate());
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day} ${month}, ${year}`;
  };

  const getDayWithOrdinalSuffix = (day) => {
    if (day >= 11 && day <= 13) {
      return `${day}th`;
    }
    switch (day % 10) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  };

  const formattedReleaseDate = formatDate(releaseDate);

  return <span>{formattedReleaseDate}</span>;
}

export default FormattedDate;
