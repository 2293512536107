import React from "react";

const TableSkeletonLaoder = () => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-[hsl(210,12%,93%)] ">
          <tr className="px-4">
            <th className="px-6 py-4">
              <div className="skeleton-loader w-32 h-4"></div>
            </th>
            <th className="px-6 py-4">
              <div className="skeleton-loader w-28 h-4"></div>
            </th>
            <th className="px-6 py-4">
              <div className="skeleton-loader w-24 h-4"></div>
            </th>
            <th className="px-6 py-4">
              <div className="skeleton-loader w-32 h-4"></div>
            </th>
            <th className="px-6 py-4">
              <div className="skeleton-loader w-36 h-4"></div>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {Array.from({ length: 10 }, (_, index) => (
            <tr key={index} className={`${index % 2 !== 0 && "bg-gray-100"}`}>
              <td className="px-6 py-2 whitespace-nowrap sm:table-cell">
                <div className="skeleton-loader w-32 h-3"></div>
              </td>
              <td className="px-6 py-2 whitespace-nowrap sm:table-cell">
                <div className="skeleton-loader w-28 h-3"></div>
              </td>
              <td className="px-6 py-2 whitespace-nowrap sm:table-cell">
                <div className="skeleton-loader w-24 h-3"></div>
              </td>
              <td className="px-6 py-2 whitespace-nowrap sm:table-cell">
                <div className="skeleton-loader w-32 h-3"></div>
              </td>
              <td className="px-6 py-2 flex">
                <div className="skeleton-loader w-12 h-4"></div>
                <div className="skeleton-loader w-12 h-4 ml-1"></div>
                <div className="skeleton-loader w-12 h-4 ml-1"></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableSkeletonLaoder;
