import { baseApi } from "./baseApi";

const profileApi = baseApi.injectEndpoints({
        endpoints: (build) =>({
            getUserProfile: build.query({
                query:() => 'profile',
        })
        })
});

export const  {useGetUserProfileQuery} = profileApi