import { useState } from 'react';
import Header from './Header';
import { Outlet } from 'react-router-dom';
import Sidebar from './SideBar';

const DefaultLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className=" w-full flex h-[100vh] ">
      <div className="w-[200px] fixed ">
        < Sidebar  sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        </div>
        <div className="ml-0 sm:ml-0 lg:ml-[200px] w-[100%] ">
          <div className='py-2 flex items-center sticky top-0 border-b bg-white/30 z-50 backdrop-blur-md'>
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          </div>
          <main className=''>
            <div className="">
              <Outlet />
            </div>
          </main>
        </div>
    </div>
  );
};

export default DefaultLayout;
