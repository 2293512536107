import React, { useEffect } from 'react'
import RecentsBlogs from './RecentsBlogs'
import { useGetBlogsListQuery } from '../../../redux/features/blogSlice'
import { useState } from 'react'
import { useGetSubscribersQuery } from '../../../redux/features/subscribersSlice'

function SummaryOverview() {
  const[focusGroup, setFocusGroup] = useState(0);
  const[clinitianGroup, setClinitianGroup] = useState(0);
  const {data:blogs} = useGetBlogsListQuery();
  const {data:subcribers,isLoading} = useGetSubscribersQuery();

  useEffect(()=>{
    if(!isLoading){
      const Clinitiangroups = subcribers?.filter((sub)=>{
        return sub.groupType === 'clinitian-focus-group' 
    })
    const focusGroups = subcribers?.filter((sub)=>{
      return sub.groupType === 'user-focus-group' 
  });
Clinitiangroups.length && setClinitianGroup(Clinitiangroups.length) 
focusGroups.length > 0 ? setFocusGroup(focusGroups.length): setFocusGroup(0)
    }
  
  },[focusGroup,clinitianGroup,isLoading,subcribers])


  return (
    <div className="w-full max-w-[337px]">
    <div className="w-full shadow-primaryShadow border bg-white border-[#F5F5FA] rounded-2xl p-5">
      <h1 className="text-base text-[#344054] font-semibold">Summary</h1>
      <div className="mt-3 grid grid-flow-row gap-y-2">
        <div className="w-full bg-sky-100 text-blue-500 p-2 px-3 rounded-xl flex justify-between items-center">
          <p className="inline-flex items-center gap-2 tex">
            <span className="h-1.5 w-1.5 rounded-full flex border border-gray-500"></span>
            <span>Blogs</span>
          </p>
          <p className="bg-gray-50 py-0.5 px-2 rounded-xl">{blogs?.length}</p>
        </div>
        <div className="w-full  bg-purple-200 text-purple-900 p-2 px-3 rounded-xl flex justify-between items-center">
          <p className="inline-flex items-center gap-2 tex">
            <span className="h-1.5 w-1.5 rounded-full flex border border-gray-500"></span>
            <span>Subscribers</span>
          </p>
          <p className="bg-gray-50 py-0.5 px-2 rounded-xl">{subcribers?.length}</p>
        </div>
        <div className="w-full  bg-[#fdd5ed] text-pink-800 p-2 px-3 rounded-xl flex justify-between items-center">
          <p className="inline-flex items-center gap-2 tex">
            <span className="h-1.5 w-1.5 rounded-full flex border border-gray-500"></span>
            <span>Focus Group</span>
          </p>
          <p className="bg-gray-50 py-0.5 px-2 rounded-xl">{focusGroup}</p>
        </div>
        <div className="w-full bg-yellow-100 text-yellow-800 p-2 px-3 rounded-xl flex justify-between items-center">
          <p className="inline-flex items-center gap-2 tex">
            <span className="h-1.5 w-1.5 rounded-full flex border border-gray-500"></span>
            <span>Clinician Group</span>
          </p>
          <p className="bg-gray-50 py-0.5 px-2 rounded-xl">{clinitianGroup}</p>
        </div>
      </div>
    </div>
    <RecentsBlogs/>
  </div>
  )
}

export default SummaryOverview