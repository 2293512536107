import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useGetBlogDetailsQuery,
  useUpdateBlogMutation,
} from "../redux/features/blogSlice";
import FormLabel from "../sharedComponents/FormLabel";
import Spinner from "../sharedComponents/Spinner";
import Editor from "./editor/Editor";

function UpdateBlog() {
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [coverImg, setCoverImg] = useState("");
  const [blogLabel, setBlogLabel] = useState("");
  const[publishDate, setPublishDate] = useState("");
  const [loadingImage, setLoadingImage] = useState(false);
  const [formError, setFormError] = useState("");
  const [error, setError] = useState(null);
  const [updateBlogs, setUpdateBlog] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: blog } = useGetBlogDetailsQuery(id);
  const [updateBlog, { isSuccess, isLoading }] = useUpdateBlogMutation();
  const [editorState, setEditorState] = useState({ value: null });
  const formRef = useRef(null);


   //sccroll user to the div with error
   const handleScroll = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  }


  const handleChange = value => {
    setEditorState({ value });
  };

  

 

  useEffect(() => {
    if (blog && id) {
      setUpdateBlog(blog);
    }
  }, [blog, updateBlogs,id]);

  useEffect(() => {
    if (updateBlogs && id) {
      setTitle(updateBlogs.title);
      setSubTitle(updateBlogs.subTittle);
      setBlogLabel(updateBlogs.label);
      setCoverImg(updateBlogs.coverImg);
      setEditorState({value: updateBlogs.content});
      setPublishDate(updateBlogs.publishDate)
    }
  }, [updateBlogs, id]);

  // form validation
  const validateForm = () => {
    if (!title || !subTitle || !coverImg || !blogLabel ) {
      setFormError("All fields are required");
      handleScroll();
      return false;
    }
    
    return true;
  };

  const handleData = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const blogData = {
      title: title,
      subTittle: subTitle,
      content: editorState.value,
      coverImg: coverImg,
      label: blogLabel,
      publishDate:publishDate

    };

    try {
      await updateBlog({ id: id, blogData: blogData });
    } catch (error) {
      setError(error.data.error);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Blog updated successfully");
      navigate("/layout");
    }
  }, [isSuccess, navigate]);

  /**
   * cloudinary
   */
  const handleFileInputChange = async (event) => {
    const file = event.target.files?.[0];

    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("tags", "codeinfuse, medium, gist");
    formData.append("upload_preset", "coverBlogs");
    formData.append("api_key", "679389975872345");

    setLoadingImage(true);
    try {
      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/dsvqxl3uk/image/upload/",
        formData,
        {
          headers: { "X-Requested-With": "XMLHttpRequest" },
        }
      );

      const data = response.data;

      if (response.status === 200) {
        // setImageLoad(false);
        setLoadingImage(false);
      }
      setCoverImg(data.secure_url);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {isLoading && (
        <div className="flex justify-center items-center p-6 sm:p-10">
          <Spinner />
        </div>
      )}

      <div className=" my-4 mx-2 border border-gray-300 shadow-secondaryShadow  bg-white rounded-xl">
        <form className="w-full  py-2" onSubmit={handleData} ref={formRef}>
          <div className="border-b">
            <h1 className="text-2xl font-semibold mb-4 px-4 mt-3"> Edit Blog</h1>
          </div>
          {error && <p className="text-red-500">{error}</p>}
          <div className="px-4 py-6">
            <div className="mb-4">
              <FormLabel number={1} text={"Main Information"} />
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="title"
              >
                Title
              </label>
              <input
                type="text"
                placeholder="Title"
                name="title"
                className={ ` shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-blue sm:text-sm sm:leading-6 border  rounded-md py-2 px-3 w-full ${
                  formError && !title ? "border-red-500" : "border-gray-300"
                }`}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              {formError && !title && (
                <p className="text-red-500">{formError}</p>
              )}
            </div>
            <div className="mb-4 flex  flex-col  lg:flex-row  gap-x-40 ">
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="subTitle"
                >
                  Sub Title
                </label>
                <input
                  type="text"
                  placeholder="Sub Title"
                  name="subTitle"
                  className={` shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-blue sm:text-sm sm:leading-6 border  rounded-md py-2 px-3 w-full ${
                    formError  && !subTitle ? "border-red-500" : "border-gray-300"
                  }`}
                  value={subTitle}
                  onChange={(e) => setSubTitle(e.target.value)}
                />

                {formError && !subTitle && (
                  <p className="text-red-500">{formError}</p>
                )}
              </div>
              <div className="flex flex-col  ">
          <label
            className="block text-gray-700 text-sm font-bold mb-2 "
            htmlFor=" Category label"
          >
            Category label
          </label>
          <input
            type="text"
            placeholder="Category label"
            name="Category label"
            className={` shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-blue sm:text-sm sm:leading-6 border  rounded-md py-2 px-3 w-full ${
              formError  && !blogLabel ? "border-red-500" : "border-gray-300"
            }`}
            value={blogLabel}
            onChange={(e) => setBlogLabel(e.target.value)}
          />
          {formError && !blogLabel && (
            <p className="text-red-500">{formError}</p>
          )}
          </div>
          <div>
          <label
            className="block text-gray-700 text-sm font-bold mb-2 "
            htmlFor="subTitle"
          >
            Date Of Publish
          </label>
          <input
            type="Date"
            name="publishDate"
            className={` shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-blue sm:text-sm sm:leading-6 border  rounded-md py-2 px-3 w-full border-gray-300
            `}
            value={publishDate}
            onChange={(e) => setPublishDate(e.target.value)}
          />
          
          </div>
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="subTitle"
              >
                Cover img
              </label>
              <input
                type="file"
                placeholder="cover image"
                name="coverImg"
                className={` shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-blue sm:text-sm sm:leading-6 border rounded-md p-2 w-full ${
                  formError && !coverImg? "border-red-500" : "border-gray-300"
                }`}
                onChange={handleFileInputChange}
              />
              {formError && !coverImg && (
                <p className="text-red-500 ">{formError}</p>
              )}
            </div>
            <div className="control-pane">
              <FormLabel number={2} text={"Description"} />
              <div className="control-section" id="rteTools">
                <div className="rte-control-section"> 
                   <Editor handleChange={handleChange} editorState={editorState}/>
                </div>
              </div>
            </div>
            {loadingImage ? (
              <button className="py-2">uploading ....</button>
            ) : (
              <button
                className={`" text-white rounded-md py-2 px-4 mt-4 bg-primary-blue hover:bg-primary-hover 
        `}
                type="submit"
                onClick={validateForm}
                disabled={!validateForm}
              >
                Edit Blog
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
}
export default UpdateBlog;
